<template>
  <div class="col justify-center">
    <SwiperCarousel
      v-if="store.slides.length > 0"
      v-model:slide="store.selectedSlideIndex"
      :items="store.slides"
    >
      <template #item="{ item, index }">
        <TransferTaskCarouselSlide
          :slide="item"
          :index="index"
          @delete="emit('delete-selected')"
          @product-click="editingProduct = $event"
          @update:slide="store.selectedSlideIndex = $event"
        />
      </template>
      <template #container-end="{ index, total }">
        <ScrollCarouselCounter
          :current="index + 1"
          :total="total"
          :suggested="store.zeroSlideIndex + 1"
          hide-to-current-link
        >
          <template #left>
            <span
              v-if="store.nextNotCompletedIndex !== null && store.nextNotCompletedIndex !== store.selectedSlideIndex"
              class="text-underline-dotted text-blue cursor-pointer"
              @click="store.selectedSlideIndex = store.nextNotCompletedIndex"
            >
              {{ t('next') }}
            </span>
          </template>
        </ScrollCarouselCounter>
      </template>
    </SwiperCarousel>
    <div class="q-pa-lg justify-center">
      <BaseScanField
        ref="scanField"
        :hint="t('Scan and take needed Product')"
        :search-fn="searchFn"
        :placeholder="t('Product')"
        :not-found-message="t('Wrong Product')"
        :rules="rules"
        :disabled="disabled"
        no-omni-input-scan
        @scan="emit('scan', $event)"
      />
    </div>
    <ProductEditDialog
      v-if="editingProduct"
      :id="editingProduct.id"
      @cancel="editingProduct = null"
    />
  </div>
</template>

<script setup lang="ts">

import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import ScrollCarouselCounter from '@/components/Mobile/ScrollCarouselCounter.vue';
import SwiperCarousel from '@/components/Mobile/SwiperCarousel.vue';
import type { Container, Product, StorageUnit } from '@/graphql/types';
import useTransferProcessStore from '@/stores/transferProcess';
import type { FunctionValidationRule } from '@/types';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import TransferTaskCarouselSlide from '@/views/Mobile/Transfer/TransferTaskCarouselSlide.vue';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useTransferProcessStore();
const editingProduct = ref<Product | null>(null);

defineProps<{
  availableAmount?: number;
  rules?: FunctionValidationRule[];
  disabled?: boolean;
  searchFn: ((barcode: string | null) => object);
}>();

const emit = defineEmits<{
  (e: 'delete-selected'): void;
  (e: 'scan', storable: StorageUnit | Container): void;
}>();

const scanField: Ref<InstanceType<typeof BaseScanField>> = ref(null!);

defineExpose({
  scan: (value: string) => scanField.value?.scan(value),
});

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>

<i18n lang="yaml">
ru:
  next: следующий

en:
  next: next
</i18n>
