<template>
  <div class="row no-wrap justify-center items-center">
    <!--
    Используем width: 0, чтобы счетчик выше всегда оставался по центру,
    независимо от этого элемента.
    -->
    <div
      class="text-no-wrap q-mr-xs"
      style="width: 0; direction: rtl"
    >
      <slot name="left" />
    </div>
    <span>
      {{ current }} / {{ total }}
    </span>
    <!--
    Используем width: 0, чтобы счетчик выше всегда оставался по центру,
    независимо от этого элемента.
    -->
    <div
      class="text-no-wrap q-ml-xs"
      style="width: 0"
    >
      <template v-if="offset !== 0">
        (<span :class="offsetColor">{{ offsetAsString }}</span>)
        <span
          v-if="!hideToCurrentLink"
          class="text-underline-dotted text-blue cursor-pointer"
          @click="emit('to-current')"
        >
          {{ t('to current') }}
        </span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts" generic="TItem">

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();

const props = defineProps<{
  current: number;
  total: number;
  suggested: number;
  hideToCurrentLink?: boolean;
}>();

const emit = defineEmits<{
  (e: 'to-current'): void;
}>();

const offset = computed(() => props.current - props.suggested);

const offsetAsString = computed(() => offset.value > 0 ? '+' + offset.value : offset.value.toString());

const offsetColor = computed(() => offset.value > 0 ? 'text-green' : 'text-red');

</script>

<i18n lang="yaml">
ru:
  to current: на текущий

en:
  to current: to current
</i18n>
