<template>
  <QBtnDropdown
    :disable="!recommendedFile"
    :loading="fetching"
    :href="recommendedFile?.url"
    :title="recommendedFile?.name"
    :label="t('Download WMS Agent App')"
    icon="mdi-download"
    v-bind="$attrs"
    split
  >
    <QList>
      <QExpansionItem
        v-for="(r, i) in releases"
        :key="r.version"
        :label="t('Version {v}') + ' ' + r.version"
        group="versions"
        :default-opened="i === 0"
        :caption="i === 0 ? t('Latest') : undefined"
        expand-separator
      >
        <QList>
          <QItem
            v-for="file in r.files"
            :key="file.url"
          >
            <QItemSection side>
              <QIcon :name="file.icon" />
            </QItemSection>
            <QItemSection>
              <a :href="file.url">
                {{ file.name }}
              </a>
            </QItemSection>
          </QItem>
        </QList>
      </QExpansionItem>
    </QList>
  </QBtnDropdown>
</template>
<script setup lang="ts">
import type { WmsAgentRelease } from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import { useQuasar } from 'quasar';
import { ascend, when } from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const isOpen = ref(false);

const { data, fetching } = useQuery<{ releases: WmsAgentRelease[] }>({
  query: gql`
    query GetWmsAgentReleases {
      releases: wmsAgentReleases {
        version
        downloadUrls
      }
    }
  `,
});

const selectedRelease = ref('');

const { platform } = useQuasar();

const fileExtensionPreferences = {
  linux: ['deb', 'rpm', 'AppImage'],
  win: ['msi', 'exe'],
}[platform.is.platform] ?? [];

const infinityIfNegative = when<number, number>(i => i < 0, () => Number.POSITIVE_INFINITY);

const releases = computed(() => (data.value?.releases ?? []).map(r => ({
  version: r.version,
  files: r.downloadUrls.map(url => {
    const name = url.slice(url.lastIndexOf('/') + 1);
    const extension = name.slice(name.lastIndexOf('.') + 1);
    const icon = iconForExtension(extension);
    const priority = infinityIfNegative(fileExtensionPreferences.indexOf(extension));
    return { url, name, extension, icon, priority };
  }).sort(ascend(f => f.priority))
})));

const recommendedFile = computed(() => (releases.value.flatMap(r => r.files))[0] ?? null);

watch([releases, isOpen], () => {
  selectedRelease.value = releases.value[0]?.version;
});

function iconForExtension(extension: string) {
  switch (extension) {
    case 'msi':
    case 'exe':
      return 'mdi-microsoft-windows';
    case 'AppImage':
    case 'deb':
    case 'rpm':
      return 'mdi-penguin';
  }
}
</script>

<i18n lang="yaml">
ru:
  Download WMS Agent App: Скачать приложение WMS Agent
  Version {v}: Версия {v}
  Latest: Последняя

en:
  Download WMS Agent App: Download WMS Agent App
  Version {v}: Version {v}
  Latest: Latest
</i18n>
