<template>
  <QCard>
    <QLinearProgress
      :indeterminate="fetching"
      instant-feedback
    />

    <CardTitle>
      {{ t('Printers') }}

      <QSpace />

      <QBtn
        flat
        round
        icon="mdi-plus"
        color="green"
        :title="t('Add Printer')"
        :to="{ name: ROUTES.PRINTERS_NEW }"
      />
    </CardTitle>

    <QCardActions class="q-gutter-sm">
      <PrintAgentDownloadButton persistent>
        <template #activator="{ loading, preferredUrl, releases }">
          <QBtnDropdown
            split
            :href="preferredUrl"
            :disable="loading"
            :label="t('Download Print Agent')"
            icon="mdi-download"
          >
            <PrintAgentDownloadMenu :releases="releases" />
          </QBtnDropdown>
        </template>
      </PrintAgentDownloadButton>

      <CopyToClipboardButton
        :text="store.warehouse.printAgentToken"
        :label="t('Copy Key')"
      />

      <QBtn
        :href="printAgentDeepLink"
        icon="mdi-open-in-new"
        :label="t('Open Print Agent App')"
      />
      <QBtn
        :href="wmsAgentDeepLink"
        icon="mdi-open-in-new"
        :label="t('Open WMS Agent App')"
      />
      <WmsAgentDownloads />
      <ConfigureWmsAgentDialog />
    </QCardActions>

    <QSeparator />

    <QList>
      <template v-if="printers.length > 0">
        <template
          v-for="printer in printers"
          :key="printer.id"
        >
          <QItem>
            <QItemSection avatar>
              <PrinterStatus
                :printer="printer"
                size="md"
              />
            </QItemSection>
            <QItemSection>
              <QItemLabel>{{ printer.displayName }}</QItemLabel>
              <QItemLabel caption>
                {{ t(`paperFormat.${printer.paperFormat}`) }}
              </QItemLabel>
              <QItemLabel caption>
                {{ t(`printerType.${printer.__typename}`) }}
              </QItemLabel>
            </QItemSection>

            <QItemSection side>
              <div>
                <QBtn
                  flat
                  round
                  icon="mdi-pencil"
                  :to="{ name: ROUTES.PRINTERS_EDIT, params: { id: printer.id }}"
                />
                <QBtn
                  flat
                  round
                  icon="mdi-delete"
                  color="error"
                  :loading="hasProgress('deleting:' + printer.id)"
                  @click="deletePrinter(printer)"
                />
              </div>
            </QItemSection>
          </QItem>

          <QSeparator />
        </template>
      </template>
      <QItem v-else-if="!fetching">
        <QItemSection>
          {{ t('No Printers') }}
        </QItemSection>
      </QItem>
    </QList>
  </QCard>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue';
import PrintAgentDownloadButton from '@/components/PrintAgentDownloadButton.vue';
import PrintAgentDownloadMenu from '@/components/PrintAgentDownloadMenu.vue';
import PrinterStatus from '@/components/PrinterStatus.vue';
import WmsAgentDownloads from '@/components/WmsAgentDownloads.vue';
import useProgressHandling from '@/composables/useProgressHandling';
import type { MutationDeletePrinterArgs, Printer, Scalars } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import useStore from '@/stores/root';
import ConfigureWmsAgentDialog from '@/views/WarehouseSettings/ConfigureWmsAgentDialog.vue';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { progressStarted, hasProgress } = useProgressHandling<string>();

const { t } = useI18n();

const { client: urql } = useClientHandle();

const printers = ref<Printer[]>([]);

const { data: printersResult, fetching, executeQuery } = useQuery<{ printers: Printer[] }>({
  query: gql`
    query GetPrinters {
      printers {
        id displayName paperFormat
        ...on PrintAgentPrinter { agentName systemName }
        ...on WmsAgentPrinter { agentName printerName }
      }
    }
  `,
});

watch(printersResult, data => {
  printers.value = data!.printers;
});

async function deletePrinter(printer: Printer): Promise<void> {
  const done = progressStarted(`deleting:${printer.id}`);

  const { data } = await urql.mutation<{ id: Scalars['ID'] }, MutationDeletePrinterArgs>(
    gql`mutation DeletePrinter($id: ID!) { id: deletePrinter(id: $id) }`,
    { id: printer.id },
  );

  done();

  printers.value = printers.value.filter(p => p.id !== data!.id);
  await executeQuery();
}

const store = useStore();

const printAgentDeepLink = computed(() =>
  `print-agent://use-api-token?token=${store.warehouse!.printAgentToken}`,
);

const wmsAgentDeepLink = computed(() => 'wms-agent://focus');

</script>

<i18n lang="yaml">
ru:
  Printers: Принтеры
  No Printers: Нет принтеров
  Add Printer: Добавить принтер
  Print Agent: Агент печати
  Download Print Agent: Скачать Агент печати
  Copy Key: Скопировать ключ
  Open Print Agent App: Открыть приложение Агент печати
  Open WMS Agent App: Открыть приложение WMS Agent

en:
  Printers: Printers
  No Printers: No Printers
  Add Printer: Add Printer
  Print Agent: Print Agent
  Download Print Agent: Download Print Agent
  Copy Key: Copy Key
  Open Print Agent App: Open Print Agent App
  Open WMS Agent App: Open WMS Agent App
</i18n>
