<template>
  <QList separator>
    <QItem dense>
      <QItemSection>
        <QItemLabel
          header
          class="q-pl-none"
        >
          {{ t('Select Printer') }}
        </QItemLabel>
      </QItemSection>
      <QItemSection side>
        <QBtn
          flat
          round
          icon="mdi-refresh"
          class="float-right"
          @click="discover"
        />
      </QItemSection>
    </QItem>
    <TransitionGroup
      appear
      enter-active-class="animated fadeInDown"
      leave-active-class="animated fadeOutUp"
    >
      <BaseAlert
        v-if="connected && printersToShow.length === 0"
        type="warning"
      >
        {{ t('No printers') }}
      </BaseAlert>
      <QItem
        v-for="p in printersToShow"
        :key="`${p.agentName}-${p.printerName}`"
        :active="printerSelected(p)"
        clickable
        @click="selectPrinter(p)"
      >
        <QItemSection
          avatar
          style="min-width: 40px;"
        >
          <QIcon
            v-if="printerSelected(p)"
            name="mdi-check"
          />
        </QItemSection>
        <QItemSection>
          <QItemLabel>
            {{ p.printerName }}
          </QItemLabel>
          <QItemLabel
            v-if="!p.discovered"
            caption
            class="text-yellow-10"
          >
            <QIcon
              name="mdi-alert"
            />
            {{ t('Printer is unavailable') }}
          </QItemLabel>
        </QItemSection>
        <QItemSection
          side
          top
        >
          <QItemLabel caption>
            {{ p.agentName }}
          </QItemLabel>
        </QItemSection>
      </QItem>
    </TransitionGroup>
  </QList>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import usePrintingBackendWmsAgent, {
  type WmsAgentPrinterInfo,
} from '@/composables/usePrintingBackendWmsAgent';
import type { PrinterInput } from '@/graphql/types';
import { uniqBy } from 'ramda';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const wmsAgentBackend = usePrintingBackendWmsAgent();

const model = defineModel<PrinterInput>({ required: true });

const discoveredPrinters = ref<WmsAgentPrinterInfo[]>([]);

function printerSelected(p: WmsAgentPrinterInfo) {
  return p.agentName === model.value.agentName
    && p.printerName === model.value.printerName;
}

const printersToShow = computed<{
  agentName: string;
  printerName: string;
  discovered: boolean;
}[]>(() => {
  const result = discoveredPrinters.value.map(p => ({ ...p, discovered: true }));

  if (model.value.agentName && model.value.printerName && connected.value
    && result.every(p => !printerSelected(p))) {
    result.unshift({
      agentName: model.value.agentName,
      printerName: model.value.printerName,
      discovered: false,
    });
  }

  return result;
});

const connected = ref(false);

wmsAgentBackend.onConnected(() => {
  connected.value = true;
  discover();
});

function discover() {
  wmsAgentBackend.requestPrinters();
}

wmsAgentBackend.onPrinters(printers => {
  discoveredPrinters.value = uniqBy(p => [p.agentName, p.printerName],[...discoveredPrinters.value, ...printers]);
});

wmsAgentBackend.onAgentOffline(({ agentName }) => {
  discoveredPrinters.value = discoveredPrinters.value.filter(p => p.agentName !== agentName);
});

function selectPrinter(printer: WmsAgentPrinterInfo) {
  model.value = {
    ...model.value,
    agentName: printer.agentName,
    printerName: printer.printerName,
  };
}

</script>

<i18n lang="yaml">
ru:
  Select Printer: Выберите принтер
  No printers: Принтеры не найдены. Убедитесь, что WMS Agent запущен и принтер подключен
  Printer is unavailable: >
    Принтер недоступен. Проверьте, запущено ли приложение WMS Agent и подключен ли принтер
en:
  Select Printer: Select Printer
  No printers: No printers found. Ensure that WMS Agent is running and printer is connected
  Printer is unavailable: Printer is unavailable
</i18n>
