<template>
  <QPage
    padding
    class="row items-start"
  >
    <div
      v-if="fetching"
      class="col-12 text-center"
    >
      <QCircularProgress
        indeterminate
        size="150px"
        color="primary"
        font-size="0.1em"
        show-value
      >
        {{ t('Loading') }}
      </QCircularProgress>
    </div>
    <QCard
      v-else
      class="col-12 col-sm-8 col-md-6 col-lg-4"
    >
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection>
        <QInput
          v-model="editingPrinter.displayName"
          :label="t('Display Name')"
          :hint="t('Name to use in printers lists')"
          :rules="[notEmptyRule]"
          v-bind="qErrorsFor('printer.displayName')"
        />
        <QSelect
          v-model="editingPrinter.paperFormat"
          :options="formats"
          :label="t('Paper Format')"
          :option-label="paperFormatLabel"
        />
        <div class="row">
          <QCheckbox
            v-model="useAgentApp"
            :label="t('Use app')"
          />
          &nbsp;
          <QSelect
            v-if="editingPrinter.type && appTypes.has(editingPrinter.type as AppPrinterType)"
            v-model="editingPrinter.type"
            :options="[...appTypes]"
            :option-label="printerTypeLabel"
            dense
            outlined
          />
        </div>
      </QCardSection>
      <PrintAgentPrinterEdit
        v-if="editingPrinter.type === 'PrintAgentPrinter'"
        v-model="editingPrinter"
      />
      <WmsAgentPrinterEdit
        v-else-if="editingPrinter.type === 'WmsAgentPrinter'"
        v-model="editingPrinter"
      />
      <QCardActions align="between">
        <QBtn
          color="primary"
          :loading="progress.saving"
          icon="mdi-content-save"
          :disable="saveButtonIsDisabled"
          @click="save()"
        >
          {{ t('Save') }}
        </QBtn>

        <QBtn
          color="primary"
          outline
          :disabled="progress.saving"
          @click="router.push({ name: ROUTES.WAREHOUSE_SETTINGS })"
        >
          {{ t('Cancel') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useValidationRules from '@/composables/useValidationRules';
import type {
  MutationSavePrinterArgs,
  PrinterInput,
  QueryPrinterArgs,
  Scalars,
} from '@/graphql/types';
import { PrinterPaperFormatEnum } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import type { PrinterUnion } from '@/types';
import PrintAgentPrinterEdit from '@/views/WarehouseSettings/PrintAgentPrinterEdit.vue';
import WmsAgentPrinterEdit from '@/views/WarehouseSettings/WmsAgentPrinterEdit.vue';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

type PrinterType = NonNullable<PrinterUnion['__typename']>;

type AppPrinterType = Extract<PrinterType, 'PrintAgentPrinter' | 'WmsAgentPrinter'>;

const { t } = useI18n();

const { client: urql } = useClientHandle();

const { notEmptyRule } = useValidationRules();

const router = useRouter();

const {
  fillErrorsFromGraphQLError,
  clearErrors,
  getPrimaryError,
  qErrorsFor,
} = useErrorHandling();

const progress = reactive({
  discovering: false,
  saving:      false,
});

const appTypes = new Set<AppPrinterType>([
  'PrintAgentPrinter',
  'WmsAgentPrinter',
]);

const props = defineProps<{
  id?: Scalars['ID'];
}>();

const {
  data: printerResult,
  fetching,
} = useQuery<{ printer: PrinterUnion }, QueryPrinterArgs>({
  query:     gql`
    query GetPrinterForEdit($id: ID!) {
      printer(id: $id) {
        id displayName paperFormat
        ...on PrintAgentPrinter { agentName systemName }
        ...on WmsAgentPrinter { agentName printerName }
      }
    }
  `,
  variables: computed(() => ({ id: props.id! })),
  pause:     computed(() => !props.id),
});
watch(printerResult, data => {
  if (data) {
    editingPrinter.value = {
      type: data.printer.__typename!,
      id: data.printer.id,
      agentName: '',
      printerName: '',
      displayName: data.printer.displayName,
      paperFormat: data.printer.paperFormat,
    };
    switch (data.printer.__typename) {
      case 'PrintAgentPrinter':
        editingPrinter.value.agentName = data.printer.agentName;
        editingPrinter.value.printerName = data.printer.systemName;
        break;
      case 'WmsAgentPrinter':
        editingPrinter.value.agentName = data.printer.agentName;
        editingPrinter.value.printerName = data.printer.printerName;
        break;
    }
  }
});

const editingPrinter = ref<PrinterInput>({
  type:        'BrowserTabPrinter',
  id:          null!,
  agentName:   '',
  printerName: '',
  displayName: '',
  paperFormat: PrinterPaperFormatEnum.A4,
});

const useAgentApp = computed({
  get: () => appTypes.has(editingPrinter.value.type as AppPrinterType),
  set: value => {
    editingPrinter.value.type = value ? 'PrintAgentPrinter' : 'BrowserTabPrinter';
  }
});

function printerTypeLabel(type: AppPrinterType) {
  return {
    PrintAgentPrinter: t('Print Agent'),
    WmsAgentPrinter: t('WMS Agent'),
  }[type];
}

const saveButtonIsDisabled = computed(
  () => useAgentApp.value
    && (!editingPrinter.value.printerName
    || !editingPrinter.value.displayName),
);

const formats = Object.values(PrinterPaperFormatEnum);

const paperFormatLabel = (value: PrinterPaperFormatEnum) => t(`paperFormat.${value}`);

async function save(): Promise<void> {
  clearErrors();

  progress.saving = true;

  const { error } = await urql.mutation<unknown, MutationSavePrinterArgs>(
    gql`
      mutation SavePrinter($printer: PrinterInput!) {
        savePrinter(printer: $printer) { id }
      }
    `,
    { printer: editingPrinter.value },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    router.push({ name: ROUTES.WAREHOUSE_SETTINGS });
  }

  progress.saving = false;
}

</script>

<i18n lang="yaml">
ru:
  Use app: Использовать приложение
  Print Agent: Агент печати
  WMS Agent: WMS Agent
  Display Name: Выводимое имя
  Name to use in printers lists: Имя для вывода в списках для выбора
  Paper Format: Формат бумаги
en:
  Use app: Use app
  Print Agent: Print Agent
  WMS Agent: WMS Agent
  Display Name: Display Name
  Name to use in printers lists: Name to use in printers lists
  Paper Format: Paper Format
</i18n>
