<template>
  <BaseAlert
    v-if="primaryError"
    type="error"
  >
    {{ primaryError }}
  </BaseAlert>
  <ScrollCarousel
    v-if="store.carouselItems.length > 0"
    v-model="store.selectedItemIndex"
    :items="store.carouselItems"
    dont-reset-on-items-change
  >
    <template #item="{ item, index }">
      <SelectionCarouselSlide
        :item="item"
        :index="index"
        :display-amount="index === store.selectedItemIndex ? currentAmount : item.takenAmount"
        @delete="emit('delete-selected')"
        @product-click="editingProduct = $event"
        @update:slide="store.selectedItemIndex = $event"
      />
    </template>
    <template #indicators="{index, total}">
      <ScrollCarouselCounter
        :current="index + 1"
        :total="total"
        :suggested="store.currentItemIndex + 1"
        @to-current="store.resetCarouselSliderPosition()"
      />
    </template>
  </ScrollCarousel>
  <div class="q-pa-lg justify-center">
    <BaseScanField
      v-if="store.selectedItem"
      ref="scanField"
      :hint="t('Scan and take needed Product')"
      :search-fn="searchStorageUnit"
      :placeholder="t('Product')"
      :not-found-message="t('Wrong Product')"
      :loading="store.savingMovements"
      no-omni-input-scan
      @update:scanning="$event && handleBeforeScan()"
      @scan="handleStorableScan"
    />
  </div>
  <ProductEditDialog
    v-if="editingProduct"
    :id="editingProduct.id"
    @cancel="editingProduct = null"
  />
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import ScrollCarouselCounter from '@/components/Mobile/ScrollCarouselCounter.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useSpeaker from '@/composables/useSpeaker';
import type {
  Product,
  ProductPack,
  ProductPackWithAmount,
  QueryProductPackByBarcodeArgs,
} from '@/graphql/types';
import { speechOnProductPackScan } from '@/helpers/speechOnScan';
import useSelectionStore from '@/stores/selection';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import SelectionCarouselSlide from '@/views/Mobile/Selection/SelectionCarouselSlide.vue';
import { gql, useClientHandle } from '@urql/vue';
import { useEventBus } from '@vueuse/core';
import { last } from 'ramda';
import { type Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const speaker = useSpeaker();

const store = useSelectionStore();

const { primaryError, fillErrorsFromGraphQLError, getPrimaryErrorFromGraphQLError, clearErrors } = useErrorHandling();

defineProps<{
  currentAmount: string;
}>();

const emit = defineEmits<{
  (e: 'delete-selected'): void;
  (e: 'before-scan'): void;
  (e: 'scan'): void;
}>();

const { client: urql } = useClientHandle();

async function searchStorageUnit(barcode: string): Promise<ProductPack | null> {
  const exactPackItem = store.carouselItems
    .find(item => item.storageUnit.productPack.barcodes.some(b => b.barcode === barcode));

  if (exactPackItem) {
    return exactPackItem.storageUnit.productPack;
  }

  const { data, error } = await urql.query<{
    productPackByBarcode: ProductPackWithAmount;
  }, QueryProductPackByBarcodeArgs>(
    gql`
      query SearchProductPackForSelection($barcode: String!) {
        productPackByBarcode(barcode: $barcode) {
          productPack {
            id
            quantity
            smallerProductPack { id }
            product {
              id
              name
              productPacks {
                id
                quantity
                smallerProductPack { id }
              }
            }
          }
        }
      }
    `,
    { barcode },
  );

  if (error) {
    throw new Error(getPrimaryErrorFromGraphQLError(error));
  }

  if (!data?.productPackByBarcode) {
    return null;
  }

  const scannedPack = data?.productPackByBarcode.productPack;

  const productFound = store.carouselItems
    .some(item => item.storageUnit.productPack.id === scannedPack.id);

  return productFound ? scannedPack : null;
}

const { emit: emitCloseDialogs } = useEventBus('close-dialogs');

function handleBeforeScan() {
  emitCloseDialogs();
  emit('before-scan');
}

const lastScannedPack = ref<ProductPack | null>(null);

async function handleStorableScan(scannedPack: ProductPack): Promise<void> {
  clearErrors();

  const itemsWithProduct = store.carouselItems
    .filter(item => item.storageUnit.productPack.id === scannedPack.id);

  const scannedItem = itemsWithProduct.find(item => item.takenAmount < item.plannedAmount) ?? last(itemsWithProduct)!;

  if (scannedItem.takenAmount === scannedItem.plannedAmount) {
    speaker.speak(t(
      'Excess product, only {needed} needed',
      { needed: scannedItem.plannedAmount },
    ));
    store.selectItemOnCarousel(scannedItem);
    return;
  }

  if (scannedPack.id !== lastScannedPack.value?.id) {
    speaker.speak(speechOnProductPackScan(scannedPack));
    lastScannedPack.value = scannedPack;
  }

  const existingMovement = store.itemMovement(scannedItem, store.currentSelection!.movements);

  const { error, data } = existingMovement
    ? (await store.updateMovementAmount({
      movement: existingMovement,
      amount:   existingMovement.amount + 1,
    }))
    : (await store.createMovement({
      storageFromId: store.currentStorage!.id,
      containerToId: scannedItem.storageTo.id,
      storageUnitId: scannedItem.storageUnit.id,
      amount:        1,
    }));

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  for (const item of data!.changeResult.changedSelectionOrderItems) {
    const selectionOrderItem = scannedItem.selectionOrderItems.find(soi => soi.id === item.id)!;
    scannedItem.takenAmount += item.selectedAmount - selectionOrderItem.selectedAmount;
    selectionOrderItem.selectedAmount = item.selectedAmount;
  }

  if (scannedItem.plannedAmount > 1) {
    speaker.speak(t('{amount} out of {total}', {
      amount: scannedItem.takenAmount,
      total:  scannedItem.plannedAmount,
    }));
  }

  if (scannedItem.takenAmount === scannedItem.plannedAmount) {
    lastScannedPack.value = null;
    if (store.containers.length > 1) {
      speaker.speak(scannedItem.storageTo.name);
    }
  }
  store.adjustScannedItemPosition(scannedItem);

  if (store.currentStorageFullySelected) {
    store.currentStorage = null;
  }

  emit('scan');
}

const editingProduct = ref<Product | null>(null);

const scanField: Ref<InstanceType<typeof BaseScanField>> = ref(null!);

defineExpose({
  scan: (value: string) => scanField.value?.scan(value),
});

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml" />
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml" />
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml" />
<i18n lang="yaml">
ru:
  Remove Item?: Удалить элемент?

en:
  Remove Item?: Remove Item?
</i18n>

