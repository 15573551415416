<template>
  <QSpinnerHourglass
    v-if="status === 'checking'"
    :size
  />
  <QIcon
    v-if="status === 'ready'"
    name="mdi-check-circle-outline"
    color="success"
    :size
  />
  <QIcon
    v-if="status === 'unavailable'"
    name="mdi-alert-circle-outline"
    color="error"
    :title
    :size
  />
</template>

<script setup lang="ts">

import usePrintingBackendPrintAgent from '@/composables/usePrintingBackendPrintAgent';
import type { PrintAgentPrinter } from '@/graphql/types';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type PrintAgentStatus = 'checking' | 'ready' | 'unavailable';

const { t } = useI18n();

const { ping: pingPrintAgent } = usePrintingBackendPrintAgent();

const props = defineProps<{
  printer: PrintAgentPrinter;
  size?: string;
}>();

const status = ref<PrintAgentStatus | null>(null);

const title = computed(() => t('Print Agent "{name}" is unavailable', {
  name: props.printer.agentName,
}));

watch(() => props.printer, checkStatus, { immediate: true });

async function checkStatus() {
  if (!props.printer.agentName) {
    status.value = null;
    return;
  }

  const checkingAgent = props.printer.agentName;
  status.value = 'checking';

  const newStatus = await getStatus(props.printer.agentName);

  // Во время проверки статуса принтер мог измениться.
  // Меняем статус на полученный только если агент принтера тот же.
  if (checkingAgent === props.printer.agentName) {
    status.value = newStatus;
  }
}

async function getStatus(agentName: string): Promise<PrintAgentStatus> {
  try {
    await pingPrintAgent(agentName, 1000, 5);
    return 'ready';
  } catch {
    return 'unavailable';
  }
}

</script>

<i18n lang="yaml">
ru:
  Print Agent "{name}" is unavailable: Агент печати {name} недоступен

en:
  Print Agent "{name}" is unavailable: Print Agent "{name}" is unavailable
</i18n>
