<template>
  <QCardSection>
    <div class="row items-center q-mb-md wrap-xs">
      <StoragesSelect
        v-model="storageTo"
        :hint="t('Storage To Hint')"
        class="col-12 col-sm"
      />
    </div>
  </QCardSection>

  <PrimaryErrorBanner animated />

  <QCardActions :vertical="screen.xs">
    <QBtn
      icon="mdi-cancel"
      :label="t('Cancel')"
      @click="emit('close')"
    />

    <QSpace v-if="screen.gt.xs" />

    <QBtn
      icon="mdi-plus"
      :label="t('Create')"
      :disable="canCreate"
      :loading="creating"
      color="primary"
      @click="create"
    />
  </QCardActions>
</template>

<script setup lang="ts">

import useErrorHandling from '@/composables/useErrorHandling';
import type {
  MutationCreateRepackingTaskArgs,

  Cell,
  Container } from '@/graphql/types';
import {
  type TransferTask,
} from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { useQuasar } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import StoragesSelect from '@/components/StoragesSelect.vue';

const { fillErrorsFromGraphQLError, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const { t } = useI18n();

const { screen } = useQuasar();

const emit = defineEmits<{
  close: [];
  create: [];
}>();

const storageTo = ref<Cell | Container | null>(null);

const canCreate = computed(() => storageTo.value);


async function create() {
  clearErrors();

  const { error } = await doCreate({
    targetStorageToId: storageTo.value!.id,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('create');
}

const {
  fetching: creating,
  executeMutation: doCreate,
} = useMutation<{ task: TransferTask }, MutationCreateRepackingTaskArgs>(gql`
  mutation CreateRepackingTask(
    $targetStorageToId: ID!,
  ) {
    task: createRepackingTask(targetStorageToId: $targetStorageToId) {
      id
    }
  }
`);
</script>
<i18n lang="yaml" src="../../../../plugins/i18n/sharedMessages/transfer.yaml"></i18n>
