<template>
  <div>
    <Swiper
      :modules="modules"
      :initial-slide="slide"
      virtual
      :pagination="pagination"
      :class="wrapperClass"
      :speed="200"
      @swiper="swiper = $event"
      @active-index-change="emit('update:slide', $event.activeIndex)"
    >
      <SwiperSlide
        v-for="(item, index) in items"
        :key="index"
        :virtual-index="index"
      >
        <slot
          name="item"
          v-bind="{ item, index }"
        />
      </SwiperSlide>
      <template #container-end>
        <slot
          name="container-end"
          v-bind="{ index: slide, total: items.length }"
        />
      </template>
    </Swiper>
  </div>
</template>

<script setup lang="ts" generic="TItem">

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import { Pagination, Virtual } from 'swiper/modules';
import type { Swiper as SwiperClass } from 'swiper/types';
import type { SwiperModule } from 'swiper/types/shared';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  slide: number;
  items: TItem[];
  pagination?: boolean;
  wrapperClass?: string;
}>();

const emit = defineEmits<{
  (e: 'update:slide', value: number): void;
}>();

const swiper = ref<SwiperClass | null>(null);

watch(() => props.slide, page => {
  if (page !== swiper.value?.activeIndex) {
    swiper.value?.slideTo(page, 0);
  }
});

watch(() => props.items.length, length => {
  if (props.slide >= length) {
    emit('update:slide', length - 1);
  }
});

const modules = computed<SwiperModule[]>(() => [
  Virtual,
  ...(props.pagination ? [Pagination] : []),
]);

</script>

<style scoped lang="scss">

:deep(.swiper-pagination-bullet-active) {
  background: $primary;
}

</style>
