<template>
  <QDialog
    v-model="isOpen"
    :persistent="fetching"
  >
    <QCard style="min-width: 500px">
      <CardTitle>{{ t('Configure WMS Agent App') }}</CardTitle>
      <BaseAlert type="info">
        <i18n-t keypath="A new API Token will be created {tokensPageLink}">
          <template #tokensPageLink>
            <RouterLink :to="{ name: ROUTES.API_TOKENS_LIST }">
              {{ t('Tokens List') }}
            </RouterLink>
          </template>
        </i18n-t>
      </BaseAlert>

      <QCardSection>
        <QInput
          v-model="tokenName"
          :label="t('Token Name (optional)')"
          dense
          :hint="t('Token Name Hint')"
        />
      </QCardSection>

      <PrimaryErrorBanner animated />

      <QCardActions align="between">
        <QBtn
          :label="t('Close')"
          :disable="fetching"
          @click="isOpen = false"
        />
        <QBtn
          color="primary"
          icon="mdi-auto-fix"
          :label="t('Configure App')"
          :loading="fetching"
          @click="configure"
        />
      </QCardActions>
    </QCard>
  </QDialog>

  <QBtn
    icon="mdi-auto-fix"
    v-bind="$attrs"
    :label="t('Configure WMS Agent App')"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import ROUTES from '@/router/routeNames';
import useStore from '@/stores/root';
import { gql, useMutation } from '@urql/vue';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();

const store = useStore();

const { fillErrorsFromGraphQLError, clearErrors, primaryError, PrimaryErrorBanner } = useErrorHandling();

const isOpen = ref(false);

const route = useRoute();

const configureParamName = 'configure-agent-app';

onMounted(() => {
  if (configureParamName in route.query) {
    isOpen.value = true;
  }
});

const tokenName = ref('');

const token = ref('');

const { executeMutation: doCreateToken, fetching } = useMutation<{ token: string }>(gql`
  mutation CreatePersonalAccessTokenForWmsAgentApp($name: String!) {
    token: createAccessToken(name: $name, permissions: [AGENT_APP])
  }
`);

const router = useRouter();

async function configure() {
  clearErrors();

  if (!token.value) {
    const { data, error } = await doCreateToken({
      name: tokenName.value || 'WMS Agent',
    });

    if (error) {
      fillErrorsFromGraphQLError(error);
      return;
    }

    token.value = data!.token;
  }

  const query = new URLSearchParams({
    accountName:      store.user!.username,
    pusherAuthUrl:    import.meta.env.VITE_APP_URL + '/broadcasting/auth',
    pusherAppKey:     import.meta.env.VITE_PUSHER_APP_KEY,
    pusherCluster:    import.meta.env.VITE_PUSHER_APP_CLUSTER,
    pusherChannel:    'agent-app.' + store.warehouse!.id,
    apiToken:         token.value,
    configurationUrl: new URL(router.resolve({
      name:  ROUTES.WAREHOUSE_SETTINGS,
      query: { [configureParamName]: null },
    }).href, window.location.origin).href,
  });

  if (!window.open(`wms-agent://configure?${query}`)) {
    primaryError.value = t('Unable to configure. Your browser may have blocked the pop-up');
    return;
  }

  isOpen.value = false;
}

watch(isOpen, () => {
  token.value = '';
  tokenName.value = '';
  clearErrors();
});
</script>

<i18n lang="yaml">
ru:
  Configure WMS Agent App: Настроить приложение WMS Agent
  A new API Token will be created {tokensPageLink}: >
    Будет создан новый API-токен.
    Этот токен будет использоваться приложением для доступа к WMS.
    Вы можете удалить неиспользуемые токены, перейдя в {tokensPageLink}
  Tokens List: список токенов
  Token Name (optional): Имя токена (необязательно)
  Token Name Hint: Имя для нового токена, по которому его можно будет отличить от других
  Configure App: Создать токен и настроить приложение
  Unable to configure. Your browser may have blocked the pop-up: >
    Не удалось настроить приложение. Возможно, ваш браузер заблокировал всплывающее окно
en:
  Configure WMS Agent App: Configure WMS Agent App
  A new API Token will be created {tokensPageLink}: >
    A new API Token will be created.
    This token will be used by WMS Agent app to access WMS.
    You can delete unused tokens on {tokensPageLink} page
  Tokens List: Tokens List
  Token Name (optional): Token Name (optional)
  Token Name Hint: New token name that will distinguish it from others.
  Configure App: Configure App
  Unable to configure. Your browser may have blocked the pop-up: >
    Unable to configure. Your browser may have blocked the pop-up
</i18n>
