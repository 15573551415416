<template>
  <div
    v-if="status === 'waiting'"
    :title="t('Waiting')"
  >
    <QSpinnerPuff :size />
  </div>
  <QIcon
    v-if="status === 'ready'"
    name="mdi-check-circle-outline"
    color="success"
    :size
  />
</template>

<script setup lang="ts">

import usePrintingBackendWmsAgent from '@/composables/usePrintingBackendWmsAgent';
import type { WmsAgentPrinter } from '@/graphql/types';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type AgentStatus = 'waiting' | 'ready';

const { t } = useI18n();

const wmsAgentBackend = usePrintingBackendWmsAgent();

const { printer, size } = defineProps<{
  printer: WmsAgentPrinter;
  size?: string;
}>();

const status = ref<AgentStatus>('waiting');

watch(() => printer, printer => {
  status.value = wmsAgentBackend.isAgentOnline(printer.agentName) ? 'ready' : 'waiting';
}, { immediate: true });

wmsAgentBackend.onAgentOnline(({ agentName }) => {
  if (agentName === printer.agentName) {
    status.value = 'ready';
  }
});

wmsAgentBackend.onAgentOffline(({ agentName }) => {
  if (agentName === printer.agentName) {
    status.value = 'waiting';
  }
});

</script>

<i18n lang="yaml">
ru:
  Waiting: Ожидание

en:
  Waiting: Waiting
</i18n>
